<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <v-card class="rounded-xl px-4" flat>
          <v-toolbar class="font-weight-bold d-flex justify-center" flat>
            Account Settings
          </v-toolbar>
          <v-form ref="form" class="text-left">
            <label>Full Name</label>
            <v-text-field
              rounded
              dense
              solo
              flat
              background-color="secondary"
              v-model="form.name"
            ></v-text-field>
            <label>Company / Organization</label>
            <v-text-field
              rounded
              dense
              solo
              flat
              background-color="secondary"
              v-model="form['company']"
            ></v-text-field>
            <label>Job Title / Bio</label>
            <v-text-field
              rounded
              dense
              solo
              flat
              background-color="secondary"
              v-model="form['job_title']"
            ></v-text-field>
            <label>Email</label>
            <v-text-field
              rounded
              dense
              solo
              flat
              background-color="secondary"
              v-model="form.email"
            ></v-text-field>
            <v-checkbox
              true-value="yes"
              false-value="no"
              v-model="form['new_recording_notify']"
              color="primary"
              class="my-0 py-0"
            >
              <template v-slot:label>
                <small> Notify me when new recording is received </small>
              </template>
            </v-checkbox>
            <v-checkbox
              false-value="no"
              true-value="yes"
              v-model="form['usage_notification']"
              color="primary"
              class="my-0 py-0 mt-n3"
            >
              <template v-slot:label>
                <small> Send me usage notification emails </small>
              </template>
            </v-checkbox>
          </v-form>
          <v-card-actions class="pb-7">
            <v-spacer></v-spacer>
            <v-btn
              @click="updateUser"
              :loading="loading"
              class="capitalize primary px-5"
              rounded
            >
              save changes
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="rounded-xl px-4" flat>
          <v-toolbar class="font-weight-bold d-flex justify-center" flat>
            Profile Picture
          </v-toolbar>
          <v-form ref="form" class="text-left">
            <v-list-item>
              <v-avatar size="80">
                <v-img :src="user['image'] ? user['image'] : avatar"></v-img>
              </v-avatar>
              <v-spacer></v-spacer>
              <v-file-input
                style="display: none"
                id="selectImage"
                @change="uploadImage()"
                v-model="form.file"
              ></v-file-input>
              <v-btn
                onclick="document.getElementById('selectImage').click()"
                class="primary capitalize px-5"
                rounded
                text
                :loading="loadImage"
              >
                change avatar
              </v-btn>
              <v-spacer></v-spacer>
            </v-list-item>
            <div class="font-weight-bold text-center mt-7 mb-3">Password</div>
            <label>Current Password</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              placeholder="Password"
              :type="!showPassword ? 'password' : 'text'"
              v-model="form['old_password']"
            >
              <template v-slot:append>
                <v-icon
                  @click="showPassword = !showPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
            <label>New Password</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              placeholder="Password"
              :type="!showNewPassword ? 'password' : 'text'"
              v-model="form['password']"
            >
              <template v-slot:append>
                <v-icon
                  @click="showNewPassword = !showNewPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showNewPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
            <label>Confirm New Password</label>
            <v-text-field
              solo
              flat
              dense
              rounded
              background-color="#eff3ff"
              placeholder="Password"
              :type="!showConfirmPassword ? 'password' : 'text'"
              v-model="form['password_confirmation']"
            >
              <template v-slot:append>
                <v-icon
                  @click="showConfirmPassword = !showConfirmPassword"
                  color="grey"
                  class="pointer"
                >
                  {{
                    !showConfirmPassword
                      ? "mdi-eye-outline"
                      : "mdi-eye-off-outline"
                  }}
                </v-icon>
              </template>
            </v-text-field>
          </v-form>
          <v-card-actions class="pb-7">
            <v-spacer></v-spacer>
            <v-btn
              @click="changePassword"
              :loading="loadPassword"
              class="capitalize primary px-5"
              rounded
            >
              save changes
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <konvey-plans v-if="isStandardPlan" :user="user"></konvey-plans>
        <app-sumo-plans v-if="isAppSumoPlan" :user="user"></app-sumo-plans>
        <lifetime-plans v-if="isLifetimePlan" :user="user"></lifetime-plans>
        <v-card class="mt-4 px-7 pb-7 rounded-xl" flat>
          <v-toolbar class="font-weight-bold d-flex justify-center" flat>
            Account Operations
          </v-toolbar>
          <v-btn
            @click="deleteAccount"
            class="capitalize px-5"
            color="error"
            outlined
            rounded
            small
            text
            :loading="loadDelete"
          >
            Delete account
          </v-btn>
          <strong class="d-block text-left mt-3 error--text lighten-2">
            Note!
          </strong>
          <small class="d-block text-left text-12">
            Once you delete your account, you will delete all of the recordings
            you still have.
          </small>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import KonveyPlans from "../../components/ProfilePackages/KonveyPlans.vue";
import AppSumoPlans from "../../components/ProfilePackages/AppSumoPlans.vue";
import LifetimePlans from "../../components/ProfilePackages/LifetimePlans.vue";
import ResponseHelper from "../../utils/ResponseHelper";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    KonveyPlans,
    AppSumoPlans,
    LifetimePlans,
  },
  name: "Profile",
  data() {
    return {
      avatar: require("../../assets/avatar.png"),
      form: {},
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      toast: new ResponseHelper(),
      loading: false,
      loadImage: false,
      loadPassword: false,
      loadDelete: false,
      update: false,
    };
  },
  created() {
    this.form = Object.assign({}, this.user);
    setTimeout(() => {
      this.updateUser();
      this.update = true;
    }, 1000);
  },
  computed: {
    ...mapGetters("profile", ["user"]),
    isAppSumoPlan() {
      return this.user.payment.upgrade.plan_type === "appsumo";
    },
    isStandardPlan() {
      return this.user.payment.upgrade.plan_type === "standard";
    },
    isLifetimePlan() {
      return this.user.payment.upgrade.plan_type === "lifetime";
    },
  },
  methods: {
    ...mapActions({
      update_profile: "profile/update_profile",
      change_password: "profile/change_password",
      delete_account: "profile/delete_account",
    }),
    async updateUser() {
      this.loading = true;
      this.update = false;
      try {
        const response = await this.update_profile(this.form);
        this.loading = false;
        if (!this.update) {
          this.toast.sendSuccess(response);
        }
      } catch (error) {
        this.loading = false;
        this.toast.sendError(error);
      }
    },
    async uploadImage() {
      this.loadImage = true;
      try {
        let formData = new FormData();
        formData.append("image", this.form.file);
        // noinspection JSCheckFunctionSignatures
        let response = await this.update_profile(formData);
        this.loadImage = false;
        this.toast.sendSuccess(response);
      } catch (e) {
        this.loadImage = false;
        this.toast.sendError(e);
      }
    },
    async changePassword() {
      this.loadPassword = true;
      try {
        const response = await this.change_password(this.form);
        this.loadPassword = false;
        this.toast.sendSuccess(response);
      } catch (e) {
        this.loadPassword = false;
        this.toast.sendError(e);
      }
    },
    async deleteAccount() {
      try {
        let confirmDelete = confirm(
          "Are you sure? this action is not reversible"
        );
        if (confirmDelete) {
          this.loadDelete = true;
          const response = await this.delete_account();
          this.loadDelete = false;
          this.$store.commit("auth/LOGOUT");
          this.toast.sendSuccess(response);
          await this.$router.replace({ name: "Login" });
        }
      } catch (e) {
        this.loadDelete = false;
        this.toast.sendError(e);
      }
    },
  },
};
</script>

<style scoped></style>
